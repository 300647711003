import React, { Fragment } from 'react';
import './Footer.css';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

import { Button, Grid, Paper, Typography } from '@material-ui/core';
// import cnLogo from '../../images/cnLogo.png'
// import enLogo from '../../images/enLogo.png'
import i18n from '../../i18n';
// import logo from '../../images/swapAllLogoH.png'
// import SwapAllLogo from '../../images/SwapAllLogo.png'
// import disc from '../../images/disc.png'
// import tw from '../../images/tw.png'
// import wb from '../../images/wb.png'
// import wx from '../../images/wx.png'
// import telegram from '../../images/telegram.png'
// import xmly from '../../images/xmly.png'
// import smallOtc from '../../images/smallOtc.png'
// import ScrollToTop from './ScrollToTop.js';
import whiteLogo from '../../images/whiteLogo.png'
import location from '../../images/location.png'
import email from '../../images/email.png'
import phone from '../../images/phone.png'
import smallOtc from '../../images/smallOtc.png'




function Footer({ t, width }) {
    const useStyles = makeStyles({
        footer: {
            // position: 'absolute',
            bottom: 0,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#010846'
        },
        footerText: {
            color: 'white',
            display: 'flex',
            justifyContent: 'center'
        },
        textButton: {
            fontSize: 16, color: 'white', fontWeight: 600, marginRight: 4, textTransform: 'none', textAlign: 'start', minWidth: 60
        },
        navBut: { color: 'white', fontWeight: 'bold', marginTop: 4, margin: 0, textTransform: "none" }

    });
    const history = useHistory();
    const classes = useStyles();

    const changeLanguage = (e) => {
        let newLang = i18n.language === 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }

    const handleOpenLink = (to) => {
        // popupState.close
        window.open(to);

    };
    return (
        <div style={{ width: '100%', backgroundColor: '#091B78', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

            <Grid xs={12} container style={{ width: '84%', marginTop: 60 }}>
                <Grid item xs={12} md={6} style={{}}>

                    <img style={{ height: 80, width: 80 }} src={whiteLogo} />
                    <div style={{ marginTop: -24,marginRight:100 }}>


                        <h4 className="textfont" style={{ marginBottom: 0, fontSize: 16, color: 'white' }}>{t('title')}</h4>
                        <h4 className="textfont" style={{ marginTop: 10, fontSize: 12, color: 'white' }}>{t('description')}</h4>
                    </div>


                </Grid>
                <Grid item xs={12} md={6} style={{  flexDirection: 'column' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ height: 24, width: 24 }} src={location} />
                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}> 5255 Yonge St #1220, North York, ON M2N 6P4</h4>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ height: 24, width: 24 }} src={email} />
                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}>info@bipayinc.com</h4>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ height: 24, width: 24 }} src={phone} />
                        <h4 className="textfont" style={{ marginLeft: 8, fontSize: 12, color: 'white' }}>+16475512567</h4>
                    </div>

                </Grid>
            </Grid>



            <Grid xs={12} container style={{ width: '84%', marginBottom: 32, display: 'flex', alignItems: 'center' }}>
                <div style={{ backgroundColor: 'white', height: 1, opacity: 0.2, width: '100%', marginTop: 32, marginBottom: 32 }} />

                <Typography style={{ fontSize: 12, color: 'white', fontWeight: 600, marginRight: 4, textTransform: 'none', textAlign: 'start' }}>
                    © 2021 BiPay Inc. All right reserved
                </Typography>



                {/* <Button onClick={() => { history.push('privacy') }} className='button__text'>
                    <Typography style={{ fontSize: 12, color: 'white', fontWeight: 600, marginRight: 4, textTransform: 'none', textAlign: 'start' }}>
                        Privacy Policy
                    </Typography>
                </Button> */}
            </Grid>
        </div >
    )
}



export default withTranslation()(Footer);

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css';
import { withTranslation } from "react-i18next";
import { ThemeProvider } from '@material-ui/core/styles';
import Footer from './components/Footer/Footer';

import Navbar from './components/NavBar/Navbar'
import Home from './components/HomePage/Home';

import useWindowDimensions from "./utils/WindowDimensions";
import { Typography } from '@material-ui/core';

function App({ t }) {
    const [navBarHeight, setNavBarHeight] = useState(0)

    const windowDimensions = useWindowDimensions();
    const WIDTH = windowDimensions.width
    const HEIGHT = windowDimensions.height
    return (
        <Router>
            <div className='root__container' style={{ width: '100%' }}>
                <Navbar width={WIDTH} style={{ width: '100%' }} />
                <Switch>
                    <Route exact path='/' >
                        <Home height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight} />
                    </Route>
                </Switch>
            </div>
            <Footer width={WIDTH} />

        </Router>
    );
}

export default withTranslation()(App);

import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './Home.css';
import { isMobile } from "react-device-detect";
// import { FormatNumber } from "../../utils/FormatNumber";

import { roundingDown } from "../../utils/RoundingDown";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import headImg from '../../images/headerImage.png'
import buttonIcon from '../../images/buttonIcon.png'
import shapeLogo from '../../images/shapeLogo.png'
import feature1 from '../../images/feature1.png'
import feature2 from '../../images/feature2.png'
import feature3 from '../../images/feature3.png'

import i18n from "../../i18n";
import { Helmet } from "react-helmet";

const largeBreakPoint = 1400
const midBreakPoint = 960
const table1 = 1665
const table2 = 840

function Home({ height, width, t }) {

    const openLayer2 = () => {
        const newWindow = window.open('https://swapalltrade.com', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    // const { swapAllInfo, tokenInfo, latestBlogs } = useSelector(state => state.home)
    // const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const history = useHistory();

    // const initApidata = () => {
    //     let lang = i18n.language === 'cn' ? 0 : 1
    //     dispatch(homeActions.getSwapAllCoinDetail());
    //     dispatch(homeActions.getSwapAllDetail());
    //     dispatch(homeActions.getLatestBlogs(lang))
    // }


    // useEffect(() => {
    //     initApidata()
    //     setLoading(false)
    //     return () => {
    //     }
    // }, [])



    // useEffect(() => {
    //     if (!loading) {
    //         // updateSummaryInfo()
    //     }
    //     return () => {
    //     }
    // }, [loading])

    // useEffect(() => {
    //     if (!loading) {
    //         initApidata()
    //     }
    // }, [i18n.language])




    const dividerLine = () => {
        return (

            <div style={{ backgroundColor: 'white', height: 1, opacity: 0.2, width: '100%', marginTop: height * 0.08, marginBottom: height * 0.08 }} />


        );
    }

    const handleOpenLink = (to) => {
        window.open(to);

    };
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >

            {/* header area */}
            <Grid container style={{ width: '80%', marginTop: 40 }}>
                <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <h1 style={{ fontSize: width < 900 ? 28 : 48 }} className="textfont">{t('title')}</h1>
                    <h4 style={{ marginTop: 0 }} className="textfont">{t('description')}</h4>
                    {/* <div style={{width:'100%', marginTop: 24 ,display:'flex'}}>
                        <div style={{ height: 52, width: 52, borderRadius: 60, backgroundColor: '#283B94',alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img className="imageResizeToContain" style={{ height: '80%', width: '80%', }} src={buttonIcon}>
                            </img>
                        </div>
                    </div> */}
                </Grid>
                <Grid xs={12} md={6} item style={{ height: 400 }}>
                    <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={headImg}>

                    </img>

                </Grid>

            </Grid>



            {/* aboutUs area */}
            <div style={{ width: '80%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
                <img style={{ height: 60, width: 60, marginTop: 40 }} src={shapeLogo}></img>
                <h3 style={{ marginTop: 24 }} className="textfont">{t('aboutUs')}</h3>
                <h5 style={{ marginTop: 0, textAlign: 'center', width: width < 900 ? 400 : 600 }} className="textfont">{t('aboutUsContent')} </h5>

            </div>


            {
                width > 900 ?
                    <div style={{ width: '80%' }}>
                        {/* Feature 1 area */}
                        < Grid container style={{ marginTop: 40, alignItems: 'center' }}>
                            <Grid xs={12} md={6} item style={{ height: 520 }}>
                                <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature1}>

                                </img>

                            </Grid>
                            <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                                <div style={{ width: "70%", height: '100%' }}>
                                    <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature1Title')}</h1>
                                    <h4 className="textfont">{t('feature1Content')}</h4>
                                </div>
                            </Grid>

                        </Grid>

                        {/* Feature 2 area */}
                        <Grid container style={{ marginTop: 40, alignItems: 'center' }}>
                            <Grid xs={12} md={6} item style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <div style={{ width: "84%" }}>
                                    <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature2Title')}</h1>
                                    <h4 className="textfont">{t('feature2Content')}</h4>
                                </div>
                            </Grid>
                            <Grid xs={12} md={6} item style={{ height: 520 }}>
                                <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature2}>

                                </img>

                            </Grid>
                        </Grid>

                        {/* Feature 3 area */}
                        <Grid container style={{ marginTop: 40, alignItems: 'center' }}>
                            <Grid xs={12} md={6} item style={{ height: 520 }}>
                                <img className="imageResizeToContain" style={{ height: '100%', width: '100%', }} src={feature3}>

                                </img>

                            </Grid>
                            <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                                <div style={{ width: "70%" }}>
                                    <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature3Title')}</h1>
                                    <h4 className="textfont">{t('feature3Content')}</h4>
                                </div>
                            </Grid>

                        </Grid>

                    </div>
                    :
                    <div style={{ width: '80%' }}>
                        {/* Feature 1 area */}
                        < Grid container style={{ marginTop: 40 }}>
                            <Grid xs={12} md={6} item style={{ height: 300 }}>
                                <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature1}>
                                </img>
                            </Grid>
                            <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: "70%" }}>
                                    <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature1Title')}</h1>
                                    <h4 className="textfont">{t('feature1Content')}</h4>
                                </div>
                            </Grid>

                        </Grid>

                        {/* Feature 2 area */}
                        <Grid container style={{ marginTop: 40 }}>
                            <Grid xs={12} item style={{ height: 300 }}>
                                <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature2}>
                                </img>
                            </Grid>
                            <Grid xs={12} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: "70%" }}>
                                    <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature2Title')}</h1>
                                    <h4 className="textfont">{t('feature2Content')}</h4>
                                </div>
                            </Grid>

                        </Grid>

                        {/* Feature 3 area */}
                        <Grid container style={{ marginTop: 40 }}>
                            <Grid xs={12} item style={{ height: 300 }}>
                                <img className="imageResizeToContain" style={{ height: '100%', width: '100%' }} src={feature3}>
                                </img>
                            </Grid>
                            <Grid xs={12} item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: "70%" }}>
                                    <h1 style={{ fontSize: width < 900 ? 20 : 40 }} className="textfont">{t('feature3Title')}</h1>
                                    <h4 className="textfont">{t('feature3Content')}</h4>
                                </div>
                            </Grid>

                        </Grid>

                    </div>
            }
            <div style={{ height: 100 }}></div>
        </div >
    )
}




export default withTranslation()(Home);
